// src/components/page/anv/Navigation.js
import React, {useState, useEffect} from 'react';
import navStyle from './Navigation.module.css'
import { useIntl } from 'react-intl';
import apiClient from "../../../utils/axiosConfig"; // 确保导入 App.css


const Navigation = ({show, onClose}) => {
    const intl = useIntl();
    const [links, setLinks] = useState([]);

    useEffect(() => {
        apiClient.get('/api/navigation').then(
            response => {
                if (response.data && response.data) {
                    setLinks(response.data);
                } else {
                    console.error("Invalid response data: total_visits is missing or not valid");
                }
            }
        ).catch(error => {
            console.error('Error fetching navigation links:', error);
        })
    }, []);

    if (!show) return null;

    return (
        <div className={navStyle.navFormContainer}>
            <button className={navStyle.closeButton} onClick={onClose}>
                X
            </button>
            <div className={navStyle.navLinks}>
                <a href="/" target="_blank" rel="noopener noreferrer" className={navStyle.navLink}>
                    {intl.formatMessage({id: 'nav.home'})}
                </a>

                {links.map((link, index) => (
                    <a
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={navStyle.navLink}
                    >
                        {link.name}
                    </a>
                ))}


            </div>
        </div>
    );
};

export default Navigation;