// Header.js
import React from 'react';
import headerStyle from './Header.module.css';
import { useIntl } from 'react-intl';

const Header = ({onMenuModal,  onToggleModal, onRegisterModal }) => {
    const intl = useIntl();

    // if (typeof toggleModalHandler !== 'function' || typeof registerModalHandler !== 'function') {
    //     throw new Error('Expected functions for onToggleModal and onRegisterModal');
    // }

    return (
        <header className={headerStyle.header}>
            <button className={headerStyle.menuButton} onClick={() => onMenuModal?.()}
                    alt={intl.formatMessage({id: 'header.menu'})}>
            </button>

            <button className={headerStyle.toggleButton} onClick={() => onToggleModal?.()}
                    alt={intl.formatMessage({id: 'header.find'})}>
            </button>

            <button className={headerStyle.registerButton} onClick={() => onRegisterModal?.()}
                    alt={intl.formatMessage({id: 'header.register'})}>
            </button>
        </header>
    );
};

export default Header;
