   // messages.js
   export const messages = {
       'header.find': 'Pixel',
       'header.menu': 'Menu',
       'header.register': 'Register',
       'footer.p': '版权所有 © 2024 TechHive | 京ICP备2024089106',
       'nav.home': '首页',
       'nav.hyxj': '汉语新解',
       'nav.drawing': '自由绘画',
       'nav.mountain': '观山',
       'nav.im': 'IM系统',
   };