// src/components/Home.js
import React, { useState, useEffect } from 'react';
import homeStyles from './Home.module.css';

import UniverseBackground from './UniverseBackground';
import Register from './page/register/Register';
import PixelAvatarsModal from './page/pixel/PixelAvatarsModal';
import Header from './header/Header'; // 引入 Header 组件
import Footer from './footer/Footer'; // 引入 Footer 组件
import Content from './content/Content'; // 引入 Content 组件
import Navigation from './page/nav/Navigation'; // 引入 Navigation 组件
import apiClient from '../utils/axiosConfig';

function Home() {
    const [showModal, setShowModal] = useState(false);
    const [showMenu, setMenuModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [registeredUsers, setRegisteredUser] = useState([]);

    const toggleModal = () => setShowModal((prev) => !prev);

    const menuModal = () => setMenuModal((prev) => !prev);
    const toggleRegisterModal = () => setShowRegisterModal((prev) => !prev);
    const handleRegisterSuccess = (registeredUsers) => {
        setRegisteredUser(registeredUsers);
    };

    useEffect(() => {
        const trackAnonymousVisit = async () => {
            // apiClient.post('/api/trackVisit', { ipAddress: "localhost" });
            // .catch(error => {
            //     console.error('Error tracking anonymous visit:', error);
            // });
            try {
                // 获取用户的 IP 地址
                const response = await fetch('https://ipinfo.io/json');
                const data = await response.json();
                const ipAddress = data.ip; // 获取 IP 地址

                // 发送 IP 地址到后端
                await apiClient.post('/api/trackVisit', { ipAddress });
            } catch (error) {
                console.error('Error tracking anonymous visit:', error);
            }
        };
        // 页面加载时记录匿名用户的访问
        trackAnonymousVisit();
    }, []);

    return (
        <div className={homeStyles.modalOpen}>
            <Header onMenuModal={menuModal} onToggleModal={toggleModal} onRegisterModal={toggleRegisterModal} />
            {/* <Navigation />  */}

            {showModal ? (
                <div className={homeStyles.centerModal}>
                    <div className={homeStyles.modalContent}>
                        <PixelAvatarsModal
                            show={showModal}
                            onClose={toggleModal}
                        />
                    </div>
                </div>
            ) : null}

            {showRegisterModal ? (
                <div className={homeStyles.centerRegister}>
                    <div className={homeStyles.registerContent}>
                        <Register
                            show={showRegisterModal}
                            onClose={toggleRegisterModal}
                            onRegisterSuccess={handleRegisterSuccess}
                        />
                    </div>
                </div>
            ) : null}

            {showMenu ? (
                <div className={homeStyles.centerMenu}>
                    <div className={homeStyles.menuContent}>
                        <Navigation
                            show={showMenu}
                            onClose={menuModal}
                        />
                    </div>
                </div>
            ) : null}

            <Content />
            <UniverseBackground registeredUsers={registeredUsers} />
            <Footer />
        </div>
    );
}

export default Home;